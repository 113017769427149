@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

  html {
    @apply h-full;
  }

  body {
    @apply h-full bg-primary;
  }

  #root {
    @apply min-h-full flex flex-col;
  }

  .font-outline-1 {
    @apply drop-shadow-[0_1.2px_1.2px_rgba(255,255,255,0.8)];
  }

  .font-outline-2 {
    @apply drop-shadow-[0_2.4px_2.4px_rgba(255,255,255,0.8)];
  }

  .font-outline-3 {
    @apply drop-shadow-[0_3.6px_3.6px_rgba(0,0,0,0.8)];
  }

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
